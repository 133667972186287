import React from 'react'
import { Row, Col, Input, AutoComplete, Button } from 'antd'

const { TextArea } = Input;

class Contact extends React.Component {
    state = {
        dataSource: [],
    };
    handleChange = value => {
        this.setState({
            dataSource:
                !value || value.indexOf('@') >= 0
                    ? []
                    : [`${value}@gmail.com`, `${value}@hotmail.com`, `${value}@outlook.com`],
        });
    };
    render() {
        return (
            <Row className="Contact" type="flex" justify="center">
                <Col className="Contact-Map" span={23}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15603.671731539647!2d-77.04378688236316!3d-12.117767548606354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c822d2d95673%3A0x62078ab7e1dec08d!2sBolognesi%20125%2C%20Miraflores%2015074%2C%20Per%C3%BA!5e0!3m2!1ses-419!2sco!4v1579541876399!5m2!1ses-419!2sco" width="100%" height="550" frameborder="0" style={{ border: 0 + "px" }} allowfullscreen=""></iframe>
                </Col>
                <Col className="Contact-Form" span={23}>
                    <Row type="flex">
                        <Col md={8} xs={22}>
                            <h2>CONTACTO</h2>
                            <p><b>Dirección:</b> Calle Bolognesi 125<br />
                                Oficina 401 Miraflores, Lima Perú.</p>
                            <p>Tel: +511 4800706</p>
                            <div>
                                <i className="fab fa-facebook-f" style={{ fontSize: 1.2 + "vw", marginTop: 2 + "vh" }}></i><i className="fab fa-linkedin-in"></i>
                            </div>
                        </Col>
                        <Col className="Contact-Form-Container" md={16} xs={22}>
                            <h2>ENVÍANOS UN MENSAJE</h2>
                            <label>Nombre *</label>
                            <Input placeholder="Ingrese su nombre" />
                            <label>Email *</label><br />
                            <AutoComplete
                                dataSource={this.state.dataSource}
                                onChange={this.handleChange}
                                style={{ width: 100 + "%" }}
                                placeholder="Email *"
                                className="input_email_footer" />
                            <label>Dirección *</label>
                            <Input placeholder="Dirección" />
                            <label>Asunto *</label>
                            <Input placeholder="Asunto" />
                            <label>Mensaje *</label>
                            <TextArea
                                placeholder="Mensaje"
                            />
                            <Button type="primary">Enviar</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default Contact;