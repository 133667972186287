import React from 'react';
import { Row, Col } from 'antd';
import Img1 from '../.././Assets/Section4_img1.jpg';
import Img2 from '../.././Assets/Section4_img2.jpg';
import Img3 from '../.././Assets/Section4_img3.jpg';


function Section4() {
    return (
        <div>
            <Row className="section-4-row" type="flex" justify="center" align="middle">
                <Col md={20} xs={18}>
                    <Row type="flex" justify="space-between">
                        <Col md={6} xs={20}>
                            <h3>BRINDAMOS</h3>
                        </Col>
                        <Col span={24} className="Content">
                            <Row type="flex" justify="space-between">
                                <Col md={7} xs={24}>
                                    <Row>
                                        <Col><img src={Img1} alt="Imagen no disponible" /></Col>
                                        <Col>
                                            <p>Confiabilidad para el manejo de sus embarques en contenedores y carga consolidada, garantizado con un elevado servicio al cliente.</p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={7} xs={24} className="section-4-cols">
                                    <Row>
                                        <Col><img src={Img2} alt="Imagen no disponible" /></Col>
                                        <Col>
                                            <p>Acelere su carga aérea con nosotros, ofrecemos las mejores opciones de vuelo para que su producto sea embarcado sin complicaciones. </p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={7} xs={24} className="section-4-cols">
                                    <Row>
                                        <Col><img src={Img3} alt="Imagen no disponible" /></Col>
                                        <Col>
                                            <p>Brindamos soluciones a medida para carga sobrepeso y sobredimensionada, nuestra amplia experiencia  garantiza el éxito del proyecto.</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default Section4;
