import React from 'react';
import { Row, Col } from 'antd';

function Section2() {
    return (
        <div>
            <Row className="section-2-row" type="flex" align="middle" justify="center">
                <Col md={7} xs={20} className="section-2-row-col">
                    <Row type="flex" align="middle">
                        <Col span={3}><i className="fas fa-th"></i></Col>
                        <Col span={16} xs={18}> <h4>CONEXIONES GLOBALES</h4></Col>
                        <Col offset={4} span={20} className="section-2-row-description">
                            Somos parte de una amplia red global de agentes que nos permite transportar sus cargas desde los principales puertos y aeropuertos del mundo.
                        </Col>
                    </Row>
                </Col>
                <Col md={7} xs={20} className="section-2-row-col">
                    <Row type="flex" align="middle">
                        <Col span={3}><i className="fas fa-briefcase"></i></Col>
                        <Col span={17} xs={18}> <h4>SEGURIDAD Y CONFIANZA</h4></Col>
                        <Col offset={4} span={20} className="section-2-row-description">
                            Nuestra mejora continua a través de años de experiencia en el sector garantiza un servicio acompañado de altos estándares de calidad.
                        </Col>
                    </Row>
                </Col>
                <Col md={7} xs={20} className="section-2-row-col">
                    <Row type="flex" align="middle">
                        <Col span={3}><i className="fas fa-file-alt"></i></Col>
                        <Col span={14} xs={18}> <h4>CONTROL OPERATIVO</h4></Col>
                        <Col offset={4} span={20} className="section-2-row-description">
                            Contamos con un excelente equipo de profesionales con probada experiencia, para el control y seguimiento operativo de sus embarques.
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default Section2;
