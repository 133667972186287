import React from 'react'
import { Row, Col } from 'antd'


const About = () => {

    return (
        <Row>
            <Col span={24}>
                <Row type="flex" justify="center" align="middle" className="About">
                    <Col md={24} className="About-header">
                        <Row>
                            <Col className="About-header-Shadow" span={24}>
                                <Row type="flex" align="middle" justify="center" className="About-header-Container">
                                    <Col md={19}>
                                        <h2>Buscamos el éxito de sus operaciones, para lo cual nos comprometemos<br />
                                            siempre a brindar lo mejor de nosotros, es nuestro principal objetivo¡</h2>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24} className="About-content">
                        <Row type="flex" align="middle" justify="center">
                            <Col md={24}>
                                <h1>NOSOTROS</h1>
                            </Col>
                            <Col md={18} className="About-content-section1">
                                <p>NAVEGANTES MULTIMODAL S.A. OFRECE SOLUCIONES LOGÍSTICAS VÍA MARÍTIMA Y AÉREA DESDE EL PUNTO DE RECOJO
                                    HASTA EL LUGAR REQUERIDO DE ENTREGA PARA SUS OPERACIONES DE COMERCIO EXTERIOR ACOMPAÑADO
                                    DE UN SERVICIO ÁGIL Y DINÁMICO
                                </p>
                            </Col>
                            <Col md={16} className="About-content-section2">
                                <p>
                                    Nos apoyamos en nuestra amplia red de agentes a nivel global, que nos permiten cubrir con éxito el movimiento de
                                    carga a diferentes destinos y en ese sentido cumplir con nuestro propósito de consolidar clientes, que confían en
                                    nuestra honestidad y profesionalismo. Buscamos construir relaciones comerciales de largo plazo y en ese sentido
                                    entendemos que debemos estar en constante mejora de nuestros procesos, a fin de adaptarnos inmediatamente a las
                                    necesidades de cada negocio.
                                </p>
                            </Col>
                            <Col md={24}>
                                <h2>PILARES ESTRATÉGICOS</h2>
                            </Col>
                            <Col md={16} className="About-content-section3">
                                <Row>
                                    <Col>
                                        <h3>Visión</h3>
                                        <p>
                                            NAVEGANTES MULTIMODAL S.A. busca posicionarse en 10 años como la agencia Nro 1 en
                                            volumen de embarques de importación y exportación, brindando servicios de logística integral,
                                            acompañado de un alto nivel de atención al cliente, a fin de ser reconocidos como líderes y
                                            referentes en el transporte internacional de mercancías en contribución al desarrollo del comercio
                                            exterior peruano.
                                        </p>
                                    </Col>
                                    <Col>
                                        <h3>Misión</h3>
                                        <p>
                                            Brindar servicios de Agenciamiento de Carga Internacional que responda a la demanda de los
                                            Importadores y Exportadores del Perú conforme a sus requerimientos, a través de nuestra red de
                                            agentes en el mundo, tecnología, infraestructura y equipo humano, en procura de un servicio
                                            competitivo en costos, ágil, dinámico y cuyo compromiso es el cliente.
                                        </p>
                                    </Col>
                                    <Col>
                                        <h3>Ética</h3>
                                        <ul>
                                            <li> - Honorabilidad hacia nuestros clientes</li>
                                            <li> - Resguardar y mantener la confidencialidad de la información interna y externa.</li>
                                            <li> - No participar bajo ninguna circunstancia de negocios prohibidos por la ley</li>
                                            <li> - Respeto a las demás culturas y tradiciones, asicomo rechazar cualquier acto de discriminación.</li>
                                            <li>- Tomar decisiones estratégicas siempre en beneficio de la organización.</li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default About;