import React, { Component } from 'react';
import '../dist/css/main.min.css'
import '../dist/fontawesome/css/all.min.css'
import { Link } from 'react-router-dom';
import { Menu, Row, Col } from 'antd';

//Assets
import Logo from '.././Assets/logo_navegantes.png';



const HeaderMenu = ({ menu, updateMenuState }) => {

    const handleClick = e => {
        let path = e.key
        updateMenuState(path);
    }
    const handleClickLogo = e => {
        let path = "/"
        updateMenuState(path = "/");
    }
    return (
        <div>
            <Row className="Header-info" type="flex" justify="space-between">
                <Col md={4} xs={4}>
                    <i className="fab fa-facebook-f" style={{ fontSize: 1 + "vw", marginTop: 2 + "vh" }}></i><i className="fab fa-linkedin-in"></i>
                </Col>
                <Col md={8} xs={20}>
                    <p ellipsis>CALLE BOLOGNESI 125 OFICINA 403 MIRAFLORES LIMA - PERÚ</p>
                </Col>
            </Row>
            <Row className="Heder-menu-row" type="flex" align="middle">
                <Col span={11}>
                    <Link to="/" onClick={handleClickLogo}><img src={Logo} id="logo" alt="Logo no disponible" /></Link>
                </Col>
                <Col span={13}>
                    <Menu onClick={handleClick} selectedKeys={[menu]} mode="horizontal" className="Header-menu">
                        <Menu.Item key="/">
                            <Link to="/">HOME</Link>
                        </Menu.Item>
                        <Menu.Item key="/nosotros">
                            <Link to="/nosotros">NOSOTROS</Link>
                        </Menu.Item>
                        <Menu.Item key="/soluciones">
                            <Link to="/soluciones">SOLUCIONES</Link>
                        </Menu.Item>
                        <Menu.Item key="/contacto">
                            <Link to="/contacto">CONTACTENOS</Link>
                        </Menu.Item>
                    </Menu>
                </Col>
            </Row>
        </div>
    );

}

export default HeaderMenu;
