import React from 'react';
import { Row, Col } from 'antd';


function Section6() {
    return (
        <div>
            <Row className="section-6-row">
                <Col span={24}>
                    <Row>
                        <Col span={24}>
                            <h3>Transporte de Carga Internacional para Importaciones y Exportaciones</h3>
                        </Col>
                        <Col className="section-6-row-img1" span={12}>
                            <div></div>
                        </Col>
                        <Col className="section-6-row-img2" span={12}>
                            <div></div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default Section6;
