import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Switch, Route, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import HeaderMenu from './Components/header';
import Home from './Components/home';
import About from './Components/about'
import Contact from './Components/contact'
import Footer from './Components/footer';

class App extends Component {
constructor(props) {
  super(props);
  this.props = props;
  this.state = {
    menu: this.props.location.pathname
  }
  this.menuState = this.menuState.bind(this);
}
menuState(path = "/") {
  this.setState({ menu: `${path}` })
}
render() {
  return (
    <Row>
      <Col span={24} className="Header-content">
        <HeaderMenu
          menu={this.state.menu}
          updateMenuState={this.menuState}
        />
      </Col>
      <Col span={24}>
        <Switch>
          <Route exact path="/" component={Home} >
             <Home updateMenuState={this.menuState} />
          </Route>
          <Route exact path="/nosotros" component={About} />
          <Route exact path="/contacto" component={Contact} />
        </Switch>
      </Col>
      <Col span={24} className="Footer">
        <Footer />
      </Col>
    </Row>
  );
}
}
const ShowTheLocationWithRouter = withRouter(App);

export default ShowTheLocationWithRouter;
