import React from 'react';
import { Row, Col, Button } from 'antd';


function Section3() {
  return (
    <div>
        <Row className="section-3-row" type="flex" justify="center" align="middle">
            <Col md={18} xs={20}>
                <Row type="flex" justify="center" align="middle">
                    <Col span={24}>
                        <h3>COMERCIO INTERNACIONAL</h3>
                    </Col>
                    <Col span={24}>
                        <p>DEDICADOS A PROVEER SOLUCIONES LOGÍSTICAS AL SERVICIO DEL TRANSPORTE DE CARGA<br/>
                           INTERNACIONAL EN UN MUNDO GLOBALIZADO</p>
                    </Col>
                    <Col span={24}>
                        <Button className="btn" type="primary">Leer Más</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>
  );
}

export default Section3;
