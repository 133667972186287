import React from 'react';
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';


const Section5=({updateMenuState})=> {
    const handleClick= e => {
        let path = "/contacto"
        updateMenuState(path = "/contacto");
    }
    return (
        <div>
            <Row className="section-5-row" type="flex" justify="center" align="middle">
                <Col md={18} xs={18}>
                    <Row type="flex" justify="space-between" align="middle">
                        <Col span={22}>
                            <Row>
                                <Col>
                                    <h3>CONTÁCTANOS</h3>
                                </Col>
                                <Col>
                                    <p>Permítenos brindarte el mejor servicio
                                con la tarifa mas competitiva.</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={2} xs={20}>
                            <Link to="/contacto"><Button type="default" onClick={handleClick} className="btn" >Contacto</Button></Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default Section5;
