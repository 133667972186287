import React, { Component } from 'react';
import { Row, Col, AutoComplete, Button } from 'antd';
import { Link } from 'react-router-dom';
import Logo from '.././Assets/logo_navegantes_footer.png';


class Footer extends Component {
    state = {
        dataSource: [],
    };
    handleChange = value => {
        this.setState({
            dataSource:
                !value || value.indexOf('@') >= 0
                    ? []
                    : [`${value}@gmail.com`, `${value}@hotmail.com`, `${value}@outlook.com`],
        });
    };
    render() {
        return (
            <Row>
                <Col span={24}>
                    <Row className="Footer-row" type="flex" justify="center" align="middle">
                        <Col span={18}>
                            <Row type="flex" justify="space-between" align="middle">
                                <Col span={12}><Link to="/"><img src={Logo} /></Link></Col>
                                <Col span={10} xs={24} md={8}>
                                    <Row justify={"end"}>
                                        <Col>
                                            <p>Registra tu correo para recibir noticias de ámbito logístico y tarifas promocionales¡ </p>
                                        </Col>
                                        <Col>
                                            <AutoComplete
                                                dataSource={this.state.dataSource}
                                                style={{ width: 200 }}
                                                onChange={this.handleChange}
                                                placeholder="Email *"
                                                className="input_email_footer" />
                                        </Col>
                                        <Col>
                                            <Button type="default" className="btn" >Registrar</Button>
                                        </Col>
                                        <Col>
                                            <i className="fab fa-facebook-f" style={{ fontSize: 1.3 + "vw", marginTop: 2 + "vh" }}></i><i className="fab fa-linkedin-in"></i>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row className="Header-info" type="flex" justify="space-between">
                        <Col offset={1} md={12} xs={12}>
                            <p ellipsis>NAVEGANTES MULTIMODAL S.A.</p>
                        </Col>
                        <Col md={8} xs={22}>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );

    }
}
export default Footer;
