import React from 'react';

import { Carousel, Row, Col } from 'antd';

class CarouselHome extends React.Component {
    constructor(props) {

        super(props);

        this.props = {
            ColwithMd: 24
        }
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);

    }

    next() {
        this.slider.next();
    }

    previous() {
        this.slider.prev();
    }
    render() {
        const settings = {
            dots: true,
            infinite: true,
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
        }
        return (
            <div>
                <Carousel ref={c => (this.slider = c)} {...settings}>
                    <div className="Carousel-1">
                        <Row type="flex" style={{ height: 75 + 'vh' }} className="Carousel-1-row" justify="center" align="middle">
                            <Col offset={0} md={this.props.ColwithMd} xs={20} value={100}>
                                <h2>Transporte de<br /> Carga Marítima</h2>
                                <p>FIABILIDAD OPERATIVA Y EXCELENTES COSTOS QUE LE PERMITEN CUMPLIR<br />
                                    COMPETITIVAMENTE CON SUS CLIENTES </p>
                            </Col>
                        </Row>
                    </div>
                    <div className="Carousel-2">
                        <Row type="flex" style={{ height: 75 + 'vh' }} className="Carousel-2-row" justify="center" align="middle">
                            <Col md={this.props.ColwithMd} xs={20} value={100}>
                                <h2>Logística <br /> Integral</h2>
                                <p>INTEGRAMOS TODO EL PROCESO LOGÍSTICO DE SUS OPERACIONES<br />
                                    AGILIZANDO Y SIMPLIFICANDO SU CADENA DE ABASTECIMIENTO</p>
                            </Col>
                        </Row>
                    </div>
                    <div className="Carousel-3">
                        <Row type="flex" style={{ height: 75 + 'vh' }} className="Carousel-3-row" justify="center" align="middle">
                            <Col offset={0} md={this.props.ColwithMd} xs={20} value={100}>
                                <h2>Transporte de<br /> Carga Aérea</h2>
                                <p>SOLUCIONES FLEXIBLES QUE HACEN POSIBLE EL EMBARQUE<br />
                                    DE LA CARGA MAS URGENTE</p>
                            </Col>
                        </Row>
                    </div>
                </Carousel>
                <div id="previous" onClick={this.previous}><i className="fas fa-chevron-left"></i></div>
                <div id="next" onClick={this.next}><i className="fas fa-chevron-right"></i></div>
            </div>
        );
    }
}

export default CarouselHome;
