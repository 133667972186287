import React from 'react';
import CarouselHome from './home/carousel';
import Section2 from './home/section2';
import Section3 from './home/section3';
import Section4 from './home/section4';
import Section5 from './home/section5';
import Section6 from './home/section6';
import { Row, Col } from 'antd';


const Home = ({updateMenuState}) => {
  return (
    <div>
      <Row>
        <Col span={24}>
          <CarouselHome />
        </Col>
        <Col span={24} className="section-2">
          <Section2 />
        </Col>
        <Col span={24} className="section-3">
          <Section3 />
        </Col>
        <Col span={24} className="section-4">
          <Section4 />
        </Col>
        <Col span={24} className="section-5">
          <Section5 updateMenuState={updateMenuState} />
        </Col>
        <Col span={24} className="section-6">
          <Section6 />
        </Col>
      </Row>
    </div>
  );

}

export default Home;
